//
// halfviz.js
//
// instantiates all the helper classes, sets up the particle system + renderer
//
(function($){
  
  trace = arbor.etc.trace
  objmerge = arbor.etc.objmerge
  objcopy = arbor.etc.objcopy
  var parse = Parseur().parse

  HalfViz = function(elt, finalData, current_index){
    var dom = $(elt)

    sys = arbor.ParticleSystem(1000, 200, 0.5, true)
    sys.renderer = Renderer("#churnMatches") // our newly created renderer will have its .init() method called shortly by sys...
    sys.screenPadding(20)
    

    var _canvas = dom.find('#churnMatches').get(0)
    var _grabber = dom.find('#grabber')
    
    var _updateTimeout = null
    var _current = null // will be the id of the doc if it's been saved before
    var _editing = false // whether to undim the Save menu and prevent navigating away
    var _failures = null

    var that = {
      init:function(){
        $(window).resize(that.resize)
        that.resize()
      //  that.updateLayout(Math.max(1, $(window).width()-340))

      //  _code.keydown(that.typing)
        _grabber.bind('mousedown', that.grabbed)
        that.loadSubSet(current_index);       

        return that
      },
      
      reloadData:function(data, index){
          //set ajax here
          // modify the graph in the particle system
          finalData = data;
          current_index = index;
          that.loadSubSet(current_index);
      },

      loadSubSet:function(index){
        current_index = index;
        that.updateGraph(function(){
          that.resize();
        });
      },


      updateGraph:function(callback){
        var src_txt = finalData[current_index];
        var network = parse(src_txt)
        $.each(network.nodes, function(nname, ndata){
          if (ndata.label===undefined) ndata.label = nname
        })
        sys.merge(network)
        _updateTimeout = null;
        callback();
      },
      
      resize:function(){       
        var w = dom.width();
        that.updateLayout(w)
        sys.renderer.redraw()
      },
      
      updateLayout:function(split){
        var w = dom.width()
        var h = _canvas.height
        var split = split || _grabber.offset().left
        var splitW = _grabber.width()
        _grabber.css('left',split)


        var canvW = split - splitW
        var canvH = h
        _canvas.width = canvW
        _canvas.height = canvH
        sys.screenSize(canvW, canvH)
                
      },
      
      grabbed:function(e){
        $(window).bind('mousemove', that.dragged)
        $(window).bind('mouseup', that.released)
        return false
      },
      dragged:function(e){
        var w = dom.width()
        that.updateLayout(Math.max(10, Math.min(e.pageX-10, w)) )
        sys.renderer.redraw()
        return false
      },
      released:function(e){
        $(window).unbind('mousemove', that.dragged)
        return false
      }
    }
    
    return that.init()    
  }


  // $(document).ready(function(){
  //   var mcp = HalfViz("#halfviz")    
  // })

  
})(this.jQuery);